import React from 'react';
import { PaginationWrapper, PaginationElement } from '../elements';

import { connect } from 'react-redux';

import { pagination } from '../state/actions';
const Pagination = ({
  isFirst,
  isLast,
  prevPage,
  nextPage,
  locale,
  isDark,
  dispatch,
}) => {
  const gallery = require('../utils/lingua')(locale)('Gallery');

  const firstTabIndex = isFirst ? '-1' : '0';
  const lastTabIndex = isLast ? '-1' : '0';
  const reduxPaginationClick = (evt) => {
    dispatch(pagination(evt));
  };
  const reduxPaginationKeyDown = (evt, page) => {
    if (evt.nativeEvent.key === 'Enter') {
      dispatch(pagination(page));
    }
  };

  return (
    <PaginationWrapper isFirst={isFirst} isLast={isLast} isDark={isDark}>
      <PaginationElement
        to={`../../${prevPage}`}
        tabIndex={firstTabIndex}
        onClick={(evt) => {
          reduxPaginationClick(`../../${prevPage}`);
        }}
        onKeyDown={(evt) => {
          reduxPaginationKeyDown(evt, `../../${prevPage}`);
        }}
        >
        {gallery.previousPage}
      </PaginationElement>
      <PaginationElement
        to={`../../${nextPage}`}
        tabIndex={lastTabIndex}
        onClick={(evt) => {
          reduxPaginationClick(`../../${nextPage}`);
        }}
        onKeyDown={(evt) => {
          reduxPaginationKeyDown(evt, `../../${nextPage}`);
        }}
      >
        {gallery.nextPage}
      </PaginationElement>
    </PaginationWrapper>
  );
};

const mapStateToProps = (state) => ({ paginationString: state.pagination });
export default connect((state) => mapStateToProps)(Pagination);
