import styled from 'styled-components';

export const SnipcartButtonWrapper = styled.div``;
export const SnipcartBasketWrapperLight = styled.div`
  text-align: center;
  .snipcart-checkout {
    background-color: hsl(227, 2%, 26%);
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
  h3 {
    font-size: 0.525rem;
    padding-bottom: 0.2rem;
  }
`;
export const SnipcartBasketWrapperDark = styled.div`
  text-align: center;
  .snipcart-checkout {
    background-color: #e7ffff;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
  h3 {
    font-size: 0.525rem;
    padding-bottom: 0.2rem;
  }
`;
export const SnipcartBasketWrapperProps = styled.div`
  padding-top: 0.3rem;
  h4 {
    font-size: 0.2rem;
    padding-bottom: 0.2rem;
  }
  color: ${(props) =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
`;

export const SnipcartWrapper = styled.div`
  grid-row: 2;
  grid-column: 1 / span 6;
  background-color: ${(props) => props.theme.colors.light2};
  background-color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  h1 {
    color: inherit;
  }
  p {
    color: inherit;
  }
  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    grid-column: 1 / span 6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
  }
`;

export const SnipcartCardWrapper = styled.div`
  display: grid;
  width: 60%;
  border: 1px solid ${(props) =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  margin: 1rem 0;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto auto auto auto;
  .cardTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column: 1;
    grid-row: 1;
    padding: 0;
    h1{
      font-size: 2rem;
      padding-top: 1rem;
    }
  }
  .cardPitch{

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column: 1;
    grid-row: 2;
  }
  .unitPrice{

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column: 1;
    grid-row: 4;
    padding-bottom: 1rem;
  }
  .cardBuy{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    grid-column: 1;
    grid-row: 3;
  }
  .cardImage{
    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column: 2;
    grid-row: 1 / span 4;
    padding: 1rem;
    img{
      width: 100%;
    }
  }
  @media ${(props) => props.theme.breakpoints.tabletLandscape}{
    width: 60%;
    .cardTitle{
      h1{
        font-size: 1.5rem;
      }
    }
    .cardPitch {
      p{
        font-size: 0.8rem;
      }
    }
    .unitPrice{
      padding-bottom: 0;
    }
  }
  
  @media ${(props) => props.theme.breakpoints.tablet}{
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: auto auto auto auto;
    width: 100%;
    .cardTitle{
      grid-column: 1;
      grid-row: 1;
      padding: 0;
    }
    .cardPitch{
      grid-column: 1;
      grid-row: 2;
    }
    .unitPrice{
      grid-column: 1;
      grid-row: 4;
    }
    .cardBuy{
      padding: 0;
      grid-column: 1;
      grid-row: 3;
    }
    .cardImage{
      grid-column: 2;
      grid-row: 1 / span 4;
      img{
        width: 100%;
      }
    }
  }
  
  @media ${(props) =>
    props.theme.breakpoints.mobile} and (orientation: portrait) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    .cardTitle{
      grid-column: 1;
      grid-row: 1;
      padding-top: 1rem;
    }
    .cardPitch{
      grid-column: 1;
      grid-row: 3;
    }
    .unitPrice{
      grid-column: 1;
      grid-row: 4;
    }
    .cardBuy{
      padding: 0;
      grid-column: 1;
      grid-row: 5;
    }
    .cardImage{
      grid-column: 1;
      grid-row: 2;
      img{
        width: 100%;
      }
    }
    
    
  }
  @media ${(props) =>
    props.theme.breakpoints.mobile} and (orientation: landscape) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: auto auto auto auto;
      width: 100%;
      .cardTitle{
        grid-column: 1;
        grid-row: 1;
        padding: 0;
      }
      .cardPitch{
        grid-column: 1;
        grid-row: 2;
      }
      .unitPrice{
        grid-column: 1;
        grid-row: 4;
        padding-bottom: 1rem;
    }
    .cardBuy{
      padding: 0;
      grid-column: 1;
      grid-row: 3;
    }
    .cardImage{
      grid-column: 2;
      grid-row: 1 / span 4;
      img{
        width: 100%;
      }
    }
  }
}
  `;

export const SnipcartButtonWrapperLight = styled.div`
  .snipcart-add-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #414244;
    background-color: #e7ffff;
    color: #414244;
    font-weight: 600;
    width: 10rem;
    cursor: pointer;
    text-align: center;
    :hover {
      // border: 2px solid #a1a2a4;
      background-color: #414244;
      color: #f1f1f1;
    }
  }
`;

export const SnipcartButtonWrapperDark = styled.div`
  .snipcart-add-item {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-row: 2;
    grid-column: 2;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #f1f1f1;
    background-color: hsl(227, 2%, 26%);
    color: #f1f1f1;
    font-weight: 600;
    width: 10rem;
    cursor: pointer;
    text-align: center;
    :hover {
      border: 2px solid #f1f1f1;
      background-color: #f1f1f1;
      color: #414244;
    }
  }
`;
