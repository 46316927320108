import styled from "styled-components"

export const NavWrapper = styled.nav`
z-index: 9;
display: grid;
grid-column: 2 / span 12;
grid-template-columns: 60px auto 60px;
position: fixed;
grid-row: 1;
top: 0;
width: 100%;
// grid-template-columns: ${props => props.theme.navHeight.sideBar};
background: ${props =>
  props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  height: ${props => props.theme.navHeight.singleLine};
.tst{
  display: grid;
grid-column: 1;
    width: ${props => props.theme.navHeight.sideBar};
}
.lhs {
display: none;
}
.textMenu {
grid-column: 1;
}
.mid-image {
display: flex;
grid-row: 1;
align-items: center;
justify-content: center;
}
.mid-text {
grid-row: 2;
display: grid;
grid-template-rows: 1fr 1fr;
}
.wml-text {
font-size: 0.6em;
color: ${props =>
  props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-weight: 100;
}
.rhs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rhs-top:hover,:focus {
  filter: brightness(130%);
}
.rhs-bottom:hover,:focus {
  filter: brightness(130%);
}

a {
  text-decoration: none;
}
.burger {
  display: none;
}
.mid-wrapper{
  padding-top: 10px;    
  display: grid;
  grid-template-rows: 2rf 1fr;
  grid-row-gap: 10px;
  align-items: center;
  justify-content: center;
  // grid-column: 1;
  &:hover,
  &:focus {
    filter: brightness(110%);
  }
}
.mid {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;  
}

@media ${props => props.theme.breakpoints.tablet} {
display: none;
z-index: 101;
grid-column: span 8;
.burger {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tst{
  display: grid;
  grid-column: 2;
  width: 100%;
}
.lhs {
  display: grid;
  grid-column: 1;
}
}
    
@media ${props => props.theme.breakpoints.mobile} {
  // grid-template-columns: 60px auto 60px;
  // grid-column: span 8;
}
`
