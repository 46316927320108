import styled from "styled-components"
export const ContactWrapper = styled.div`
  display: static;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3rem;
  grid-column-gap: ${props => props.theme.spacings.large};
  .titleStatement {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  h1 {
    text-align: center;
    color: inherit;
    padding: 2rem 0;
  }
  .MDX {
    box-shadow: none;
    grid-column: 1 / span  3;
    grid-row: 2;
    padding: 0 10rem;
    padding-bottom: 3rem;
  }
  .store{
    grid-row: 3;
    grid-column: 1 / span 2;
  }  
  
  @media ${props => props.theme.breakpoints.tablet} {
    display: grid;
    grid-column: 2 / span 6;
    padding-top: 3rem;
    padding-bottom: 2rem;
    grid-row-gap: 0;

    .MDX {
      padding: 0;
      grid-column: 1 / span 3;
    }

    .titleStatement {
      grid-column: 1 / span 3;
      justify-content: space-around;
    }
    .sideBar {
      display: none;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding-top: .4rem;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 1;
    grid-column-gap: 0;
    grid-row-gap: 0rem;
    overflow: hidden;
    .titleStatement {
    }
    h1 {
      font-size: 1.8rem;
    }
    .MDX {
      padding: 0.5rem;
      line-height: 1rem;
      blockquote {
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
`
