import React, { Component, createRef } from 'react';
import { Hamburger2ItemWrapper } from '../elements';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { hamburger } from '../state/actions';

class Hamburger3Item extends Component {
  parentRef = createRef();
  render() {
    const paginationLink = this.props.isGallery
      ? this.props.pagination === 'root'
        ? this.props.linkTo
        : this.props.pagination
      : this.props.linkTo;
    const isDark = this.props.isDark;
    const first = this.props.burger.title;
    const last = this.props.burger.contact;
    const changeRedux = this.props.menu;

    if (changeRedux.last && this.props.itemText === first) {
      this.parentRef.current.focus();
    }
    if (changeRedux.first && this.props.itemText === last) {
      this.parentRef.current.focus();
    }
    let state = { ...changeRedux }.showMenu || false;
    const tabIndex = state ? '0' : '-1';
    let shift = { ...changeRedux }.shift;
    let redux = {
      showMenu: true,
      tab: this.props.itemText,
      shift: shift,
    };
    const keyUp = (evt) => {
      if (evt.nativeEvent.key === 'Shift') {
        shift = redux.shift = false;
        transmit();
      }
    };
    const keyDown = (evt) => {
      if (evt.nativeEvent.key === 'Shift') {
        shift = redux.shift = true;
      }
      if (evt.nativeEvent.key === 'Escape') {
        evt.preventDefault();
        shift = redux.escape = true;
      }
      if (
        evt.nativeEvent.key === 'Tab' &&
        !shift &&
        this.props.itemText === last
      ) {
        redux.last = true;
        evt.preventDefault();
      }
      if (
        evt.nativeEvent.key === 'Tab' &&
        shift &&
        this.props.itemText === first
      ) {
        redux.first = true;
        evt.preventDefault();
      }
      transmit();
    };

    const transmit = () => {
      this.props.hamburger(redux);
    };
    return (
      <Hamburger2ItemWrapper
        change={state}
        isDark={isDark}
        gridRow={this.props.gridRow}
        transition={this.props.transition}
      >
        <div
          className={'menuItem'}
          onKeyDown={keyDown}
          onKeyUp={keyUp}
          role="button"
          tabIndex="-1"
        >
          <div className={'transition'}>
            <Link
              to={paginationLink}
              tabIndex={tabIndex}
              ref={this.parentRef}
            >
              <h1>{this.props.itemText}</h1>
            </Link>
          </div>
        </div>
      </Hamburger2ItemWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.hamburger,
    pagination: state.pagination,
  };
};

const mapDispatchToProps = () => {
  return {
    hamburger,
  };
};

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Hamburger3Item);
