import styled from "styled-components"
export const Hamburger2Wrapper = styled.div`
  display: none;

  .navBar {
    height: ${props => props.theme.navHeight.singleLine};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};

    grid-row: 1;
    display: grid;
    grid-template-columns: 60px auto 60px;

    cursor: pointer;
  }

  .burgerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .menu2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .mid-image {
    display: flex;
    grid-column: 1;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }

  .mid-text {
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
      }

  .wml-text {
    font-size: 1.2em;
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 100;
  }
  .rhs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .rhs-top:hover,
  :focus {
    filter: brightness(130%);
  }
  .rhs-bottom:hover,
  :focus {
    filter: brightness(130%);
  }

  a {
    text-decoration: none;
  }
  .burger {
    display: none;
  }
  .mid-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus {
      filter: brightness(110%);
    }
  }
  .mid {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .body{
  opacity: ${props=>props.state?`1`:`0`};
  transition: 1s;
}
  @media ${props => props.theme.breakpoints.tablet} {
    display: grid;
    grid-template-rows: auto auto;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
`

export const Hamburger2BodyWrapper = styled.div`
  width: 100vw;
  h1 {
    padding: 3rem 0;
    font-size: 2rem;
  }
  .navFadeWrapper {
    height: ${props=>props.state?`calc(100vh - ${props.theme.navHeight.singleLine})`:`0`};
    transition: 1s;
    display: grid;
    grid-template-rows: auto auto auto;
    overflow: hidden;
    min-height: 0;
    background-color: ${props =>
      props.isDark
        ? props.theme.colors.dark2alpha
        : props.theme.colors.light2alpha};
  }
  a {
    color: ${props =>
      !props.isDark
        ? props.theme.colors.dark2alpha
        : props.theme.colors.light2alpha};
  }

  @media ${props => props.theme.breakpoints.tablet} {
    h1 {
      padding: 0;
      font-size: 2rem;
    }
  }

  @media ${props => props.theme.breakpoints.tablet} and (orientation:portrait){
    h1 {
      padding: 2rem 0;
      font-size: 2rem;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} and (orientation:portrait) {
    h1 {
      padding: 2rem 0;
      font-size: 2rem;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} and (orientation:landscape) {
    h1 {
      padding: 0;
      font-size: 2rem;
    }
  }
`















export const Hamburger2ItemWrapper = styled.div`
grid-row: ${props => (props.gridRow ? props.gridRow : `3`)};
transition: ${props => props.transition};
display: flex;
justify-content: space-evenly;
align-content: center;
align-items: top;
  .menuItem {
    // display: ${props => (!props.change ? `none` : `grid`)};
  }
`
export const Hamburger2LinesWrapper = styled.div`
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    margin: 6px 0;
    // margin: 3px 0;
    border-radius: 1rem;
    transition: 0.6s;
  }

  .bar1 {
    transform: ${props =>
      props.change ? `translate(0px, 8.25px) rotate(-45deg)` : `none`};
  }
  .bar3 {
    transform: ${props =>
      props.change ? `translate(0px, -13.5px) rotate(45deg) ` : `none`};
  }

  .bar2 {
    opacity: ${props => (props.change ? `0` : `1`)};
  }
`
