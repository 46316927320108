// import theme from "prism-react-renderer/themes/vsDark"
import styled from 'styled-components';
export const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  h1 {
    color: ${(props) =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-size: 2.4rem;
    font-weight: 100;
  }
  .titleStatement {
  }
  .top {
    width: 60%;
  }
  .bio {
    width: 60%;
    justify-content: left;
    align-items: left;
    align-content: left;
    text-align: left;
    h2 {
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
  p {
    font-size: 0.8rem;
    font-weight: 100;
  }

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    .top {
      width: 60%;
    }
    .bio {
      width: 60%;
      padding-bottom: 1rem;
    }
    h1 {
      font-size: 2.4rem;
    }
    h2 {
      font-size: 2rem;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 100%;
    .top {
      width: 50%;
    }
    .bio {
      width: 100%;
    }
    h1 {
      font-size: 2.4rem;
    }
    h2 {
      text-align: center;
      font-size: 2rem;
    }
    
  }

  @media ${(props) => props.theme.breakpoints.mobile} and (orientation: landscape) {

    .bio {
      width: 50%;
    }
  }
  @media ${(props) => props.theme.breakpoints.mobile} and (orientation: portrait) {
    .top {
      width: 100%;
    }
    .bio {
      width: 100%;
    }
    h1 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1rem;
    }
  }
`;
