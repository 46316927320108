import styled from "styled-components"
export const GalleryWrapper = styled.div`
display: grid;
grid-template-rows: auto auto 2fr;
grid-template-columns: 1fr 1fr;
grid-row-gap: 3rem;
grid-column-gap: ${props => props.theme.spacings.large};

h1 {
  text-align: center;
  grid-row: 3;
  color: inherit;
}

p {
  grid-row: 4;
  color: inherit;
}

.top {
  grid-row: 1;
}

.MDX {
  grid-column: 1 / span 2;
  padding: 0 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  
}
.tiles {
  display: grid;
  grid-row: 2;
  grid-column: 1 / span 2;
}

.titleStatement {
  grid-column: 1 / span 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media ${props => props.theme.breakpoints.tablet} {
  display: none;
}
@media ${props => props.theme.breakpoints.mobile} {
    padding-top: 1rem;
  }
`
