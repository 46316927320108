import React from "react"
import { connect } from "react-redux"
import { switchLocale } from "../state/actions"

import { LocalizationWrapper } from "../elements"
import { navigate } from "gatsby-link"
const Localization = ({ dispatch, location, locale }) => {
  const url = location.split("/")
  locale = locale === "en" ? "ja" : "en"
  const page = url.length === 3 ? url[url.length - 1] : ""
  let localeString = locale === `en` ? `🇯🇵` : `🏴󠁧󠁢󠁳󠁣󠁴󠁿`




  const localeClick = () => {
    let newLocale = "/" + locale + "/" + page
    navigate(newLocale)
    dispatch(switchLocale(locale))
  }



  const localeKey = evt => {
    if (evt.nativeEvent.key === "Enter") {
      let newLocale = "/" + locale + "/" + page
      navigate(newLocale)
      dispatch(switchLocale(locale))
    }
  }

  return (
    <LocalizationWrapper
      onClick={localeClick}
      onKeyPress={localeKey}
      tabIndex="0"
    >
      <div className={"flag"}>{localeString}</div>
    </LocalizationWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.locale })
export default connect(state => mapStateToProps)(Localization)
