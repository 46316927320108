import styled from 'styled-components'
export const DarkModeWrapper = styled.div`
img{
  height: 30px;
  // transition:opacity 0.5s linear;
}

h1{
  // transition: filter 0.3s ease;
}
h1:hover{
  filter: brightness(50%);
  cursor: pointer;
}

`
