import React from 'react';
import { SnipcartBasket } from '../components';
import { connect } from "react-redux"
import { smartBasket } from "../state/actions"

export class SnipcartSmartBasket extends React.Component {
  async componentDidMount() {
    const url = `https://3eg6iixzeb.execute-api.us-east-1.amazonaws.com/snipcart-crawler/isEmpty`;
    const response = await fetch(url);
    const APIData = await response.json();
    if(APIData){
      this.props.smartBasket(true)
    }
  }
  render() {
    if (!this.props.hideBasket) {
      return (
        <SnipcartBasket
          isDark={this.props.isDark}
          location={this.props.location}
        />
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = state => {
  return {
    hideBasket: state.smartBasket,
  }
}

const mapDispatchToProps = () => {
  return {
    smartBasket,
  }
}

export default connect(mapStateToProps, mapDispatchToProps(), null,)(SnipcartSmartBasket)

// export default SnipcartSmartBasket;
