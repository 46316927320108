import React from 'react';
import { SnipcartWrapper } from '../elements';

import { SnipcartCard } from '../components';
import { v4 as uuidv4 } from 'uuid';
export class Snipcart extends React.Component {
  state = {
    loading: true,
    products: null,
  };
  async componentDidMount() {
    const locale = this.props.locale;
    const location = this.props.location;
    const url = `https://3eg6iixzeb.execute-api.us-east-1.amazonaws.com/snipcart-crawler/${locale}/${location}`;
    const response = await fetch(url);
    const data = await response.json();
    data.sort((a, b) => {
      return a.sort - b.sort
    })


    data.sort()
    this.setState({ products: data, loading: false });
  }
  render() {
    if (this.state.products) {
      return (
          <SnipcartWrapper isDark={this.state.isDark}>
            {this.state.products.map((value) => {
              const imageURL = `https://${value.file.bucket}.s3.amazonaws.com/public/${value.file.key}`;
              return (
                <SnipcartCard
                  key={uuidv4()}
                  item={value}
                  locale={this.state.locale}
                  customer={true}
                  imageURL={imageURL}
                  isDark={this.props.isDark}
                />
              );
            })}
          </SnipcartWrapper>
      );
    } else {
      return null;
    }
  }
}
export default Snipcart;
