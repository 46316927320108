import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { NavWrapper } from '../elements';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const Nav = ({ pathname, isDark, locale }) => {
  const data = useStaticQuery(graphql`
    query {
      light: file(relativePath: { eq: "gdb.png" }) {
        childImageSharp {
          gatsbyImageData(width: 23)
        }
      }
      dark: file(relativePath: { eq: "gdw.png" }) {
        childImageSharp {
          gatsbyImageData(width: 23)
        }
      }
    }
  `);
  
  let gdb = data.dark.childImageSharp.gatsbyImageData;
  let gdw = data.light.childImageSharp.gatsbyImageData;
  const logo = isDark ? gdb : gdw
  const image = getImage(logo)

  let nav = require('../utils/lingua')(locale)('NavbarTitle');
  let midLink = locale === 'en' ? '../../en' : '../../ja';
  return (
    <NavWrapper isDark={isDark}>
      <div className={'lhs'}>
        <div className={'burger'}></div>
      </div>
      <div className={'tst'}>
        <div className={'mid'}>
          <Link to={midLink}>
            <div className={'mid-wrapper'}>
              <div className={'mid-image'}>
                <GatsbyImage
                  image={image}
                  alt={'Site Logo and link to home page.'}
                />
              </div>
              <div className={'mid-text'}>
                <div className={'wml-text'}>
                  {nav.row1}
                </div>
                <div className={'wml-text'}>
                  {nav.row2}
                </div>
                <div className={'wml-text'}></div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </NavWrapper>
  );
};

export default Nav;