import React from "react"

import { CardWrapper, P, H2 } from "../elements"
import { Link } from "gatsby"

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const ContentCard = ({ date, title, excerpt, slug, featureImage, locale, isDark }) => {
  const image = getImage(featureImage)
  const months = require("../utils/lingua")(locale)("Months")
  const JSDate = new Date(date)
  const cardDate = `${months[JSDate.getMonth()]} ${JSDate.getFullYear()}`
  return (
    <CardWrapper isDark={isDark}>
      <Link to={`../${slug}`}>
        <div className={"topRow"}>
          <div className={"imageCell"}>
            <GatsbyImage
            image={image}
            alt={'Site Logo and link to home page.'}
          />
          </div>
          <div className={"heading"}>
            <H2 textAlign="center" margin="0 0 1rem 0">
              {title}
            </H2>
            <P>{cardDate}</P>
          </div>
        </div>
        <div className={"bottomRow"}>
          <P>{excerpt}</P>
        </div>
      </Link>
    </CardWrapper>
  )
}

export default ContentCard
