import React from "react"

// import { connect } from "react-redux"
// import {} from '../state/actions'
import {
  SnipcartButtonWrapperLight,
  SnipcartButtonWrapperDark,
  SnipcartButtonWrapper,
} from "../elements"

export const SnipcartButton = ({
  itemID,
  itemPrice,
  itemURL,
  itemDescription,
  itemImage,
  itemName,
  itemTax,
  itemDisabled,
  isDark,
  locale,
}) => {
  console.log('isDark: ',isDark)
  const sButton = () => {
    if (isDark) {
      return (
        <SnipcartButtonWrapperDark 
          className={"snipcart-overwrite"}
          isDark={isDark}
          >
          <button
            className={"snipcart-add-item"}
            data-item-id={itemID}
            data-item-price={itemPrice}
            data-item-url={itemURL}
            data-item-description={itemDescription}
            data-item-image={itemImage}
            data-item-name={itemName}
            data-item-quantity="1"
            data-item-taxes={1.0}
            disabled={false}
          >
            Add to Cart
          </button>
        </SnipcartButtonWrapperDark>
      )
    } else {
      return (
        <SnipcartButtonWrapperLight 
          className={"snipcart-overwrite"}
          isDark={isDark}
        >
          <button
            className={"snipcart-add-item"}
            data-item-id={itemID}
            data-item-price={itemPrice}
            data-item-url={itemURL}
            data-item-description={itemDescription}
            data-item-image={itemImage}
            data-item-name={itemName}
            data-item-quantity="1"
            data-item-taxes={1.0}
            disabled={false}
          >
            Add to Cart
          </button>
        </SnipcartButtonWrapperLight>
      )
    }
  }
  return <SnipcartButtonWrapper>{sButton()}</SnipcartButtonWrapper>
}
export default SnipcartButton