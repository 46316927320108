import React, {
  useContext,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  SnipcartBasketWrapperLight,
  SnipcartBasketWrapperDark,
  SnipcartBasketWrapperProps,
} from '../elements';

import { SnipcartContext } from 'gatsby-plugin-snipcart-advanced/context';

export const SnipcartBasket = ({ location, isDark }) => {
  const { state } = useContext(SnipcartContext);
  const { cartTotal, cartQuantity } = state;
  const totalFloat = cartTotal.toFixed(2);
  const total = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'gbp',
  }).format(totalFloat);

  const data = useStaticQuery(graphql`
  query {
    light_basket: file(relativePath: { eq: "basket_dark.png" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
    dark_basket: file(relativePath: { eq: "basket_light.png" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
  }
`);

  let dark_basket = data.dark_basket.childImageSharp.gatsbyImageData;
  let light_basket = data.light_basket.childImageSharp.gatsbyImageData;

  const logo = isDark ? dark_basket : light_basket;
  const basket = getImage(logo);
  if (isDark) {
    return (
      <div>
        <SnipcartBasketWrapperLight className={'snipcart-overwrite'}>
          <button className={'snipcart-checkout'}>
          <GatsbyImage
            image={basket}
            alt={'Shopping cart.'}
          />
          </button>
        </SnipcartBasketWrapperLight>
        <SnipcartBasketWrapperProps isDark={isDark}>
          <h4>Quantity: {cartQuantity}</h4>
          <h4>Total: {total}</h4>
        </SnipcartBasketWrapperProps>
      </div>
    );
  } else {
    return (
      <div>
        <SnipcartBasketWrapperDark className={'snipcart-overwrite'}>
          <button className={'snipcart-checkout'}>
          <GatsbyImage
            image={basket}
            alt={'Shopping cart.'}
          />
          </button>
        </SnipcartBasketWrapperDark>
        <SnipcartBasketWrapperProps isDark={isDark}>
          <h4>Quantity: {cartQuantity}</h4>
          <h4>Total: {total}</h4>
        </SnipcartBasketWrapperProps>
      </div>
    );
  }
};

export default SnipcartBasket;
