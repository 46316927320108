import React from "react"
// import React from 'react'
import {PostWrapper} from '../elements'


export const Post = ({children, isDark}) => {

  return (
    <PostWrapper isDark={isDark}>
      {children}
    </PostWrapper>
  )
}