import styled from 'styled-components'
export const FeatureImageWrapper = styled.div`
display: ${props => props.context === "gallery" ? "none" : "flex"};
// display: flex;
align-items: center;
justify-content: center;


@media ${props => props.theme.breakpoints.tablet}{
  display: flex;
}
`