import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import {
  FooterWrapper,
  FooterSocialWrapper,
  FooterSocialIcons,
  P,
} from '../elements';
import { Localization, DarkMode } from '../components';

export const Footer = ({ location, locale, isDark }) => {
  let footer = require('../utils/lingua')(locale)('Footer');
  let login = footer.login;

  const data = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: { eq: "socialMediaIcons/facebook.svg" }) {
        publicURL
      }
      instagram: file(relativePath: { eq: "socialMediaIcons/instagram.svg" }) {
        publicURL
      }
      linkedin: file(relativePath: { eq: "socialMediaIcons/linkedin.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "socialMediaIcons/twitter.svg" }) {
        publicURL
      }
      site {
        siteMetadata {
          dashboardURL
        }
      }
    }
  `);
  const dashboardURL = data.site.siteMetadata.dashboardURL;
  return (
    <FooterWrapper>
      <FooterSocialWrapper isDark={isDark}>
        <div className={'gridTemplate'}>
          <FooterSocialIcons>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={data.facebook.publicURL} alt="facebook logo" />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={data.instagram.publicURL} alt="instagram logo" />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={data.linkedin.publicURL} alt="linkedin logo" />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={data.twitter.publicURL} alt="twitter logo" />
            </a>
          </FooterSocialIcons>
          <div className={'settings'}>
            <Localization location={location} locale={locale} />
            <a href={dashboardURL} tabIndex="0">
              <h1>{login}</h1>
            </a>
            <DarkMode isDark={isDark} />
          </div>
          <div className={'copyright'}>
            <P size="xSmall" color="dark3">
              © 2020 Gabrielle Devine
            </P>
            <P size="xSmall" color="dark3">
              All rights reserved
            </P>
          </div>
        </div>
      </FooterSocialWrapper>
    </FooterWrapper>
  );
};
export default Footer;
