import React from "react"
import { Hamburger2BodyWrapper } from "../elements"
import { Hamburger3Item } from "../components"
import { connect, useSelector } from "react-redux"
// import { Link } from "gatsby"
import { hamburger } from "../state/actions"
import { v4 as uuidv4 } from "uuid"
const Hamburger2Body = ({ location, isDark, dispatch }) => {
  const changeRedux = useSelector(state => state.hamburger)
  const locale = useSelector(state => state.localeString)
  const burger = require("../utils/lingua")(locale)("Hamburger")
  let state = { ...changeRedux }.showMenu||false
  let homeLink = locale === "en" ? "../../en" : "../../ja"
  let localeLink = locale === "en" ? "en" : "ja"
  const menuItemHit = evt => {
    dispatch(hamburger({ showMenu: false }))
  }
  const menuKeyDown=(evt)=>{}
  return (
    <Hamburger2BodyWrapper 
      state={state} 
      isDark={isDark}
    >
        <div
          className={"navFadeWrapper"}
          onClick={menuItemHit}
          onKeyDown={menuKeyDown}
          role="button"
          tabIndex="-1"
        >
          <Hamburger3Item
            id={uuidv4()}
            isDark={isDark}
            itemText={burger.title}
            linkTo={homeLink}
            gridRow={`1`}
            transition={`1s`}
            burger={burger}
            />
          <Hamburger3Item
            id={uuidv4()}
            isDark={isDark}
            itemText={burger.gallery}
            linkTo={`/${localeLink}/gallery`}
            gridRow={`2`}
            transition={`1.5s`}
            burger={burger}
            isGallery={true}
            />
          <Hamburger3Item
            id={uuidv4()}
            isDark={isDark}
            itemText={burger.contact}
            linkTo={`/${localeLink}/contact`}
            gridRow={`3`}
            transition={`2s`}
            burger={burger}
          />
        </div>
    </Hamburger2BodyWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.consent })
export default connect(state => mapStateToProps)(Hamburger2Body)
