import styled from "styled-components"
export const MainWrapper = styled.div`
grid-column: 1 / span 6;

@media ${ props => props.theme.breakpoints.tablet } {
  padding-top: 1rem;
  grid-column: 2 / span 6;
}

@media ${ props => props.theme.breakpoints.mobile } {
  // grid-column: 2 / span 6;
}
`
