import React, { Component, createRef } from "react"
import { Hamburger2Wrapper } from "../elements"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Hamburger2Lines, Hamburger2Body, SnipcartSmartBasket } from "../components"
import { hamburger } from "../state/actions"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

class Hamburger3 extends Component {
  parentRef = createRef()
  render() {
    const isDark = this.props.isDark
    const changeRedux = this.props.menu
    let state = { ...changeRedux }.showMenu || false
    if (changeRedux.escape) {
      this.parentRef.current.focus()
    }
    const locale = this.props.locale
    const nav = require("../utils/lingua")(locale)("NavbarTitle")
    // console.log('nav: ',nav)
    let midLink = locale === "en" ? "../../en" : "../../ja"

    const burgerAction = evt => {
      if (evt.key === "Enter" || evt.nativeEvent.type === "click") {
        this.props.hamburger({ showMenu: !state, tab: "burger" })
      }
    }

    const dismissMenu = evt => {
      if (state) {
        this.props.hamburger({ showMenu: !state })
      }
    }
    const logo = isDark ? this.props.gdb : this.props.gdw;
    const image = getImage(logo);
  
    return (
      <Hamburger2Wrapper state={state} isDark={isDark}>
        <div className={"navBar"}>
          <div
            className={"burgerContainer"}
            onClick={burgerAction}
            onKeyDown={burgerAction}
            role="button"
            tabIndex="0"
            ref={this.parentRef}
          >
            <Hamburger2Lines isDark={isDark} />
          </div>
          <div className={"mid"}>
            <Link to={midLink} onClick={dismissMenu} tabIndex="-1">
              <div className={"mid-wrapper"} tabIndex="-1">
                <div className={"mid-image"}>
                <GatsbyImage
                  image={image}
                  alt={'Site Logo and link to home page.'}
                />
                </div>
                <div className={"mid-text"}>
                  <div className={"wml-text"}>{nav.row1}</div>
                  <div className={"wml-text"}>{nav.row2}</div>
                  <div className={"wml-text"}></div>
                </div>
              </div>
            </Link>
        </div>

        <div className={"menu2"} role="button">
            <SnipcartSmartBasket isDark={isDark} location={this.props.location} />
        </div>

          </div>
        <div className={"body"}>
          <Hamburger2Body isDark={isDark} />
        </div>
      </Hamburger2Wrapper>
    )
  }
}
// const burg = useRef()
const mapStateToProps = state => {
  return {
    menu: state.hamburger,
    locale: state.localeString,
  }
}

const mapDispatchToProps = () => {
  return {
    hamburger,
  }
}

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Hamburger3)

// export default Hamburger3
