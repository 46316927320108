import React from 'react';
import { FeatureImageWrapper } from '../elements';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
export const FeatureImage = ({ fixed, context }) => {

  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "frontPage.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`);

  const con_air = fixed ? fixed : data.file.childImageSharp.gatsbyImageData;
  const seoImage = getImage(con_air);
  return (
    <FeatureImageWrapper context={context}>
      <GatsbyImage image={seoImage} alt={'Site Logo and link to home page.'} />
    </FeatureImageWrapper>
  );
};
