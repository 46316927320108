import React from "react"
import { connect } from "react-redux"

import { darkMode } from "../state/actions"

import { DarkModeWrapper } from "../elements"
const DarkMode = ({dispatch, isDark}) => {

  const toggle = () => {
    dispatch(darkMode(!isDark))    
  }
  const keyPress = (evt) => {
    if (evt.key==='Enter'){
      dispatch(darkMode(!isDark))
    }
  }
  return (
    <DarkModeWrapper onClick={() => toggle()}>
      <div 
        onKeyPress={keyPress}
        role='button'
        tabIndex="0"
      >
      <h1 >{isDark ? "🌝" : "🌒" }</h1>
      </div>
    </DarkModeWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.darkMode })
export default connect(state => mapStateToProps)(DarkMode)
