import React from 'react';
import { connect, useSelector } from "react-redux"

import {
  ContainerWrapper,
  TopWrapper,
  HeaderWrapper,
  MainWrapper,
} from '../elements';
import { useStaticQuery, graphql} from 'gatsby';

import CookieConsent from "react-cookie-consent"
import {
  Nav,
  Footer,
  SideBarLeft,
  SideBarRight,
  Hamburger3,
  Snipcart,
} from '../components';

import { gdpr } from "../state/actions"
const Container = ({ children, pathname, locale, isDark, dispatch }) => {
  const GDPR = useSelector(state => state.gdpr)
  const split = pathname.split('/');
  const snipcart_location =
    split.length <= 2
      ? 'home'
      : split[split.length - 1].length === 0 && split.length === 3
      ? 'home'
      : split[split.length - 1].length === 0 && split.length === 4
      ? split[split.length - 2]
      : split[split.length - 1];

  const data = useStaticQuery(graphql`
    query {
      light: file(relativePath: { eq: "gdb.png" }) {
        childImageSharp {
          gatsbyImageData(width: 23)
        }
      }
      dark: file(relativePath: { eq: "gdw.png" }) {
        childImageSharp {
          gatsbyImageData(width: 23)
        }
      }
    }
  `);

  let gdb = data.dark.childImageSharp.gatsbyImageData;
  let gdw = data.light.childImageSharp.gatsbyImageData;
  const sid = () => {
    dispatch(gdpr(true))
  }

  return (
    <TopWrapper>
      <Hamburger3
        locale={locale}
        location={pathname}
        isDark={isDark}
        gdb={gdb}
        gdw={gdw}
      />
      <HeaderWrapper>
        <Nav isDark={isDark} pathname={pathname} locale={locale} />
      </HeaderWrapper>
      <SideBarLeft isDark={isDark} locale={locale} />
      <ContainerWrapper isDark={isDark}>
        <MainWrapper>{children}</MainWrapper>
          <Snipcart
            locale={locale}
            isDark={isDark}
            location={snipcart_location}
          />
        <Footer location={pathname} locale={locale} isDark={isDark} />
      </ContainerWrapper>
      <SideBarRight isDark={isDark} locale={locale} location={pathname} />
      {!GDPR && (
        <CookieConsent
          cookieName="gatsby-gdpr-google-analytics"
          onAccept={() => {
            sid()
          }}
        >
          {" "}
          This website uses cookies to enhance user experience.
        </CookieConsent>
      )}

    </TopWrapper>
  );
};
const mapStateToProps = state => ({ gdpr: state.gdpr })
export default connect(state => mapStateToProps)(Container)

// export default Container;
