import React from "react";
import { SnipcartCardWrapper } from "../elements"
import { SnipcartButton } from "../components";

export const  SnipcartCard = ({ locale,  item, isDark, imageURL }) => {
  let show = true;
  let edit = true;
  return (
    <SnipcartCardWrapper isDark={isDark} visible={edit && show}>
      <div className={"cardTitle"}>
        <h1>{item.name}</h1>
      </div>
      <div className={"cardImage"}>
        <img src={imageURL} alt="place holder" />
      </div>
      <div className={"cardPitch"}>
        <p>{item.description}</p>
      </div>
      <div className={"unitPrice"}>
        <p>£{item.price/100}</p>
      </div>
      <div className={"cardBuy"}>
        <SnipcartButton
          itemID={item.id}
          itemPrice={item.price}
          itemURL={item.url}
          itemDescription={item.description}
          itemImage={imageURL}
          itemName={item.name}
          itemTax={item.tax}
          itemDisabled={item.disabled}
          isDark={isDark}
          locale={locale}
        />
      </div>
    </SnipcartCardWrapper>
  );
};
export default SnipcartCard;
