import styled from "styled-components"
export const RightSideBarItemWrapper = styled.div`
.item{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  // width: 80%;
  // display: block;
}

`
export const RightSideBarWrapper = styled.div`
// z-index: 101;
z-index: 9;
background-color: ${props =>
  props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
background: ${props =>
  props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
color: ${props =>
  props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};


position: fixed;
// left:80.5vw;
// left: 70rem;
right: 0;
top: 0;

display: grid;
width: 100%;
// width: 14vw;
max-width: ${props => props.theme.navHeight.sideBar};
// max-width: ${props => props.theme.navHeight.sideBar};
min-height: 100vh;
text-align: center;
grid-template-rows:  ${props => props.theme.navHeight.singleLine} 1fr;
.basket{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.subMenu{
  top: 0;
  width: 100%;
  z-index: 102;
  padding: 6rem 0;
  grid-row: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;  
  a{
    text-decoration: none;
  }
}
.item{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  // width: 80%;
  // display: block;
}
h1{
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-size: 1rem;
}
h2{
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-size: 1.5rem;
}
h3{
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-size: 1.5rem;
}

a{
  text-decoration: none; 
}
@media ${props => props.theme.breakpoints.tablet} {
  display: none;
}

@media ${props => props.theme.breakpoints.mobile} {
  display: none;
}
`
