import styled from 'styled-components'
export const LocalizationWrapper = styled.div`
font-size: 20px;
.flag{
  // transition: filter 0.3s ease;
}
.flag:hover, flag:focus {
  filter: brightness(50%);
  cursor: pointer;
}
`