let web_components = {
  metaData: {
    locales: [`en`, `ja`],
  },
  components: [
    {
      name: `StoreFitter`,
      description: `Dashboard for StoreFitter API`
    },
    {
      name: `Auth`,
      description: `Login to your dashboard`
    },
    {
      name: `Footer`,
      description: `Footer link to dashboard.`,
    },
    {
      name: `Dashboard`,
      description: `Dashboard forms and controls`,
    },
    {
      name: `HRText`,
      description: `Horizontal rule for front page quick jump`,
    },
    {
      name: `Home`,
      description: `The locale specific elements on the home page.`
    },
    {
      name: `ContactAndSales`,
      description: `The locale specific elements on the Contact and Sales page.`,
    },
    {
      name: `Gallery`,
      description: `The locale specific elements on the Galleries page.`
    },
    {
      name: `404`,
      description: `The text for the user to read when site has been directed to a page the browser has not been able to find.`,
    },
    {
      name: `NavbarTitle`,
      description: `The site title displayed in the navigation bar. The string is sliced into three parts each word displayed on a separate row.`,
    },
    {
      name: `Hamburger`,
      description: `The text displayed on the slide in menu used when the site is in mobile and tablet mode.`,
    },
    {
      name: `HomeQuickJumpCards`,
      description: `These cards are displayed on the home page and provide a way for you to direct your audience into a page describing the salient features of your work. You could use it explore and explain (for example) your main themes, or preferred media, or some of your best most recognized work.`,
    },
    {
      name: `Months`,
      description: `The months of the year displayed on the gallery cards.`
    }
  ],
}

const verbis = ISO639alpha2 => {
  return function (words) {
    let ling = web_components
    let lingua = []
    ling.metaData.locales.forEach(locale => {
      let test = require(`./${locale}/lingua`)
      ling.components.forEach(component => {
        let text = test.components.filter(function (lang) {
          return lang.name === component.name
        })[0]
        if (text) {
          let tmp = {
            locale: locale,
            name: component.name,
            description: component.description,
            text: text.text,
          }
          lingua.push(tmp)
        }
      })
    })
    let verbis = lingua.filter(page => {
      return page.name === words && page.locale === ISO639alpha2
    })[0]
    return verbis ? verbis.text : undefined
  }
}
module.exports = verbis
