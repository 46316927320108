export const switchLocale = (uia = "en") => {
  return {
    type: "LOCALE",
    payload: uia,
  }
}
export const darkMode = (isDark = false) => {
  return {
    type: "ISDARK",
    payload: isDark,
  }
}
export const gdpr = (isOK = false) => {
  return {
    type: "GDPR",
    payload: isOK,
  }
}
export const hamburger = (item = false) => {
  return {
    type: "HAMBURGER",
    payload: item,
  }
}
export const smartBasket = (hideBasket = false) => {
  return {
    type: "SMART_BASKET",
    payload: hideBasket,
  }
}
export const pagination = (page = "root") => {
  return {
    type: "PAGINATION",
    payload: page,
  }
}
