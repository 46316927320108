import React from "react"
import { RightSideBarWrapper } from "../elements"
import { SnipcartSmartBasket } from "../components"
import { useStaticQuery, graphql, Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"

export const SideBarRight = ({ isDark, locale, location }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { motives: { eq: "gallery" } } }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              locale
              date
            }
          }
        }
      }
    }
  `)
  let galleries = data.allMdx.edges
    .filter(edge => edge.node.frontmatter.locale === locale)
    .reduce((values, value) => {
      values.push(value.node.frontmatter)
      return values
    }, [])

  return (
    <RightSideBarWrapper isDark={isDark}>
      <div className={"basket"} role="button">
        <div>
          <SnipcartSmartBasket isDark={isDark} location={location} />
        </div>
      </div>
      <div className={"subMenu"}>
        {galleries.map(gallery => {
          return (
            <div className={"item"} key={uuidv4()}>
              <Link
                to={"../../" + locale + "/" + gallery.slug}
                key={uuidv4()}
              >
                <h1 key={uuidv4()}>{gallery.title}</h1>
              </Link>
            </div>
          )
        })}
      </div>
    </RightSideBarWrapper>
  )
}

export default SideBarRight
