import styled from "styled-components"

export const FooterWrapper = styled.footer`
  grid-column: 3 / span 2;
  min-height: 11.25rem;
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-row: 3;
  
  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    min-height: 8rem;
    padding: 1.5rem 0;
  }

`

export const FooterSocialWrapper = styled.div`
  .gridTemplate {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 0.5rem;
  }
  .settings {
    grid-row: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // text-align: center;
  }
  a{
    text-decoration: none;
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};


  }
  .copyright {
    p {
      grid-row: 3;
      text-align: center;
      flex: 0 0 100%;
    }
  }
`

export const FooterSocialIcons = styled.div`
  grid-row: 1;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  text-align: center;

  & img {
    padding: 0 1rem;
    transition: filter 0.3s ease;
    height: 22px;
  }

  & img:hover,
  img:focus {
    filter: brightness(50%);
  }
`
