import React from "react"
import {PageWrapper} from '../elements'


export const Page = ({children, isDark}) => {

  return (
    <PageWrapper isDark={isDark}>
      {children}
    </PageWrapper>
  )
}